// 000.000.000-00
export const maskCPF = (value: string) => {
  return value
    .replace(/\D/g, "")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d{1,2})/, "$1-$2")
    .replace(/(-\d{2})\d+?$/, "$1");
};

// 00.000.000/0000-00
export const maskCNPJ = (value: string) => {
  return value
    .replace(/\D/g, "")
    .replace(/(\d{2})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1/$2")
    .replace(/(\d{4})(\d)/, "$1-$2");
};

// AAA-0A00
export const maskPlate = (value: string) => {
  return value.replace(/(\w{3})(\d)/, "$1-$2").replace(/(-\d{4})\d+?$/, "$1");
};

// (00) 00000-0000
export const maskPhone = (value: string) => {
  return value
    .replace(/\D/g, "")
    .replace(/(\d{2})(\d)/, "($1) $2")
    .replace(/(\d{5})(\d)/, "$1-$2")
    .replace(/(-\d{4})(\d+?)$/, "$1");
};

// 00000-000
export const maskCEP = (value: string) => {
  return value.replace(/\D/g, "").replace(/^(\d{5})(\d{3})+?$/, "$1-$2");
};

// DD/MM/YYYY to YYYY-MM-DD
export function datePtBrToIso(data: string) {
  if (data.length != 10) return null;

  const _date = maskOnlyNumbers(data);
  const _day = _date.substr(0, 2);
  const _month = _date.substr(2, 2);
  const _year = _date.substr(4, 4);
  return [_year, _month, _day].join("-");
}

// Aceita apenas letras
export const maskOnlyLetters = (value: string) => {
  return value.replace(/[0-9!@#¨$%^&*)(+=._-]+/g, "");
};

// Aceita apenas números
export const maskOnlyNumbers = (value: string) => {
  return value.replace(/\D/g, "");
};

export const maskBrazilianCurrency = (
  value: number | string | null | undefined
) => {
  if (value == null || value == undefined) value = 0;

  if (typeof value === "string") value = Number(value);

  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(value);
};

export const maskDecimal = (value: number | string | null | undefined) => {
  if (value == null || value == undefined) value = 0;

  if (typeof value === "string") value = Number(value);

  return new Intl.NumberFormat("pt-BR", {
    style: "decimal",
    maximumFractionDigits: 2,
  }).format(value);
};

export const maskCurrency = (value: number | string | null | undefined) => {
  if (value == null || value == undefined) value = 0;

  return value
    .toString()
    .replace(/\D/g, "")
    .replace(/(\d)(\d{2})$/, "$1,$2")
    .replace(/(?=(\d{3})+(\D))\B/g, ".");
};

export const maskWeight = (value: string) => {
  return value.replace(/\D/g, "").replace(/(\d)(\d{4})$/, "$1,$2");
};

export const formatTextByMask = (text: string, mask: any) => {
  let maskedValue = "";

  switch (mask) {
    case "Integer":
      maskedValue = maskOnlyNumbers(text);
      break;
    case "Cep":
      maskedValue = maskCEP(text);
      break;
    case "Currency":
      maskedValue = maskCurrency(text);
      break;
    case "Weight":
      maskedValue = maskWeight(text);
      break;
    case "Phone":
      maskedValue = maskPhone(text);
      break;
    case "Cpf":
      maskedValue = maskCPF(text);
      break;
    default:
      maskedValue = text;
  }

  return maskedValue;
};
