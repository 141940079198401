import React from "react";

import { HeaderProps } from "../../types";

import * as Style from "./styles";

export const Header = ({ children }: HeaderProps) => {
  return (
    <Style.Container>
      <Style.NavBar>
        <Style.Title>{children}</Style.Title>
      </Style.NavBar>
    </Style.Container>
  );
};
