import React, { useRef } from "react";
import Modal from "react-modal";

import { RefuellingReceipt } from "./RefuellingReceipt";

import { useStore } from "../../context/Store";

import * as Style from "./styles";
import { useReactToPrint } from "react-to-print";

interface NotificationProps {
  shouldRefreshPageOnClose?: boolean;
}

export const Notification = ({
  shouldRefreshPageOnClose = false,
}: NotificationProps) => {
  const {
    modalIsOpen,
    changeModalIsOpen,
    typeModal,
    titleModal,
    refuellingData,
    resetRefuelingData,
  } = useStore();

  const componentRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "19px",
      background: typeModal === "error" ? "#CE0000" : "#008D0E",
      zIndex: 9999,
    },
  };

  const hasRefuellingData = refuellingData != null;

  const closeModal = () => {
    changeModalIsOpen(false);
    resetRefuelingData();

    if (shouldRefreshPageOnClose) {
      window.location.reload();
    }
  };
  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <Style.TitleModal>{titleModal}</Style.TitleModal>

      {hasRefuellingData ? (
        <RefuellingReceipt ref={componentRef} refuellingData={refuellingData} />
      ) : null}

      {hasRefuellingData ? (
        <Style.ButtonsModalContainer>
          <Style.ButtonModal autoFocus onClick={closeModal}>
            OK, FECHAR
          </Style.ButtonModal>
          <Style.ButtonModal onClick={handlePrint}>IMPRIMIR</Style.ButtonModal>
        </Style.ButtonsModalContainer>
      ) : (
        <Style.ButtonModal autoFocus onClick={closeModal}>
          OK
        </Style.ButtonModal>
      )}
    </Modal>
  );
};
