import { useState, useEffect, ChangeEvent, useRef } from "react";
import { AxiosError } from "axios";

import { useStore } from "../../context/Store";
import { API } from "../../services/api";

export default () => {
  const {
    changeUserAuthenticated,
    changeNotification,
    changeGasStation,
    changeassociadoId,
    changePlate,
    changeFuels,
  } = useStore();

  const cardNumberRef = useRef<HTMLInputElement>();

  const [gasToken, setGasToken] = useState<string>("");
  const [modalGasTokenIsOpen, setModalGasTokenIsOpen] =
    useState<boolean>(false);
  const [cardNumber, setCardNumber] = useState<string>("");

  const [alert, setAlert] = useState<string>("");

  const [isClearInput, setIsClearInput] = useState(false);

  useEffect(() => {
    if (!modalGasTokenIsOpen && cardNumberRef && cardNumberRef.current) {
      cardNumberRef.current.focus();
    }
  }, [modalGasTokenIsOpen]);

  const changeCardNumber = (event: ChangeEvent<HTMLInputElement> | any) => {
    const value = event.target.value;

    if (!event.nativeEvent.data) return;

    if (
      !Number(event.target.value[0]) &&
      String(event.target.value[0]) !== "0"
    ) {
      if (
        value.length > 1 &&
        Number(event.target.value.slice(1)) !== 0 && // Verifica se o segundo digito é diferente de 0
        !Number(event.target.value.slice(1))
      ) {
        return;
      }

      switch (value.replaceAll(" ", "").length) {
        case 4:
          getFuelsAndPlate(value);
          setCardNumber(value.toUpperCase());
          break;
        default:
          setCardNumber(value.toUpperCase());
      }
    } else {
      switch (value.replaceAll(" ", "").length) {
        case 10:
          getFuelsAndPlate(value);
          setCardNumber(value);
          break;
        case 18:
          getFuelsAndPlate(value);
          setCardNumber(value);
          break;
        default:
          setCardNumber(value);
      }
    }
  };

  const getFuelsAndPlate = async (plate: string) => {
    const idPosto = localStorage.getItem("idPosto");

    if (idPosto) {
      plate = plate.replaceAll(" ", "");
      try {
        const response = await API.get(
          `/api/v1/Associado/abastecimento-portal-posto?PostoId=${idPosto}&NClubGas=${plate}`
        );

        console.log(response);

        if (response.data.isValid) {
          const { associadoId, placa, listCombustivel } = response.data;

          changeassociadoId(associadoId);
          changePlate(placa);
          changeFuels(listCombustivel);
          changeUserAuthenticated(true);
        }
      } catch (error: AxiosError | any) {
        const errors = error.response.data.errors;
        setCardNumber("");
        if (errors) {
          setCardNumber("");
          changeNotification("error", errors.join("\n"));
        } else {
          setCardNumber("");
          changeNotification("error", "Erro no aplicativo, tente novamente!.");
        }
      }
    } else {
      setAlert("Por favor informe o TOKEN do posto");
      setModalGasTokenIsOpen(true);
    }
  };

  const deleteCardNumber = (event: any) => {
    if (event.key === "Backspace") {
      const deleteLastCharacter = cardNumber.substring(
        0,
        cardNumber.length - 1
      );
      setCardNumber(deleteLastCharacter);
    }
  };

  const changeGasToken = (event: ChangeEvent<HTMLInputElement> | any) =>
    setGasToken(event.target.value);

  const handleGasTokenSubmit = async () => {
    // debugger;
    if (gasToken) {
      try {
        const response = await API.get(
          `https://clubgas-api.azurewebsites.net/api/v1/Posto/token?TokenPosto=${gasToken}`
        );

        if (response && response.status === 200 && response.data.isValid) {
          const { idPosto, nomePosto } = response.data;

          localStorage.setItem("idPosto", idPosto);
          localStorage.setItem("nomePosto", nomePosto);

          setModalGasTokenIsOpen(false);
          changeGasStation(nomePosto);

          //Não deixa fazer o focus no campo.
          //changeNotification("success", "Dados validados com sucesso!");

          if (cardNumberRef && cardNumberRef.current) {
            cardNumberRef.current.focus();
          }
        }
      } catch (error) {
        setAlert("Erro ao obter TOKEN do posto. Por favor informe novamente.");
        setModalGasTokenIsOpen(true);
      }
    } else {
      setAlert("TOKEN do posto inválido. Por favor informe novamente.");
      setModalGasTokenIsOpen(true);
    }
  };

  const getInformation = async () => {
    const idPosto = localStorage.getItem("idPosto");

    if (idPosto) {
      try {
        const response = await API.get(
          `/api/v1/Posto/byId?PostoId==${idPosto}`
        );

        if (response && response.status === 200 && response.data.isValid) {
          setModalGasTokenIsOpen(false);
        } else {
          setAlert("TOKEN do posto inválido. Por favor informe novamente.");
        }
      } catch (error) {
        setAlert("Erro ao obter TOKEN do posto. Por favor informe novamente.");
        setModalGasTokenIsOpen(true);
      }
    } else {
      setAlert("Por favor informe o TOKEN do posto");
      setModalGasTokenIsOpen(true);
    }
  };

  return {
    gasToken,
    changeGasToken,
    handleGasTokenSubmit,
    modalGasTokenIsOpen,
    cardNumber,
    changeCardNumber,
    deleteCardNumber,
    getInformation,
    alert,
    cardNumberRef,
  };
};
