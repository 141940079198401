import styled from "styled-components";
import InputMask from "react-input-mask";

export const Container = styled.div`
  padding: 2vh 3vw;
  background-color: #1e1e1e;
`;

export const Header = styled.header`
  height: 20vh;
  background: #00930f;
  border-radius: 14px 14px 0px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
`;

export const RefuelingAuthorization = styled.h1`
  color: #fff;
  font-size: 38px;
  font-weight: 600;
  width: 65%;
`;

export const VehicleLicensePlate = styled.span`
  background: rgb(255, 255, 255);
  height: 40%;
  text-align: center;
  align-items: center;
  display: flex;
  border-radius: 14px;
  justify-content: center;
  padding: 28px;
  font-size: 68px;
  font-weight: 800;
  text-transform: uppercase;
`;

export const Body = styled.div`
  height: 76vh;
  display: grid;
  grid-template-areas: "fuel fuel fuel value";
  background: #fff;
  border-radius: 0 0 14px 14px;
`;

export const Fuel = styled.section`
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  display: flex;
`;

export const Value = styled.aside`
  grid-area: value;
  padding: 3rem;
  display: flex;

  .box {
    width: 100%;
    background: #444446;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 18px;
    justify-content: space-evenly;

    .enter-the-value {
      text-align: center;
      color: #fff;
      text-transform: uppercase;
      width: 70%;
      font-size: 28px;
    }

    input[type="text"] {
      height: 90px;
      width: 59%;
      border-radius: 14px;
      border: none;
      font-size: 38px;
      text-align: center;

      ::placeholder {
        font-weight: bold;
        opacity: 0.5;
        font-size: 38px;
        text-align: center;
      }
    }

    button {
      height: 80px;
      width: 60%;
      border-radius: 14px;
      border: none;
      background: #000;
      color: #fff;
      font-size: 30px;
      font-weight: 800;
      cursor: pointer;
    }
  }
`;

export const Input = styled(InputMask)`
  height: 90px;
  width: 59%;
  border-radius: 14px;
  border: none;
  font-size: 38px;
  text-align: center;

  ::placeholder {
    font-weight: bold;
    opacity: 0.5;
    font-size: 38px;
    text-align: center;
  }
`;

export const TitleModal = styled.h1`
  max-width: 50vw;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
`;

export const ButtonModal = styled.button`
  width: 100%;
  height: 90px;
  border-radius: 14px;
  border: none;
  font-size: 34px;
  margin-top: 4rem;
`;

export const ButtonBack = styled.button`
  height: 40px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 3px;
  border: none;
  background: transparent;
  border: 0.5px solid #fff;
  color: #fff;
  font-size: 14px;
  font-weight: 800;
  cursor: pointer;
  margin-bottom: 1rem;
`;
