import React from "react";
import { Grid, CircularProgress } from "@mui/material";

import { Notification } from "../../components/Notification";
import { CardButton } from "../../components";
import { useStore } from "../../context/Store";
import useRefuel from "./useRefuel";

import * as Style from "./styles";
import { CustomTextField } from "../../components/CustomTextField";

export const Refuel = () => {
  const {
    valueRefueling,
    changeValueRefueling,
    plate,
    fuels,
    changeInitialState,
    loading,
  } = useStore();
  const { postInformation } = useRefuel();

  const plateArray = Array.from(plate);

  const plateWithMask =
    plateArray.slice(0, 3).join("") + "-" + plateArray.slice(3, 7).join("");

  return (
    <>
      <Style.Container>
        <Style.Header>
          <Style.ButtonBack onClick={changeInitialState}>X</Style.ButtonBack>
          <Style.RefuelingAuthorization>
            ABASTECIMENTO AUTORIZADO PARA A PLACA:
          </Style.RefuelingAuthorization>
          <Style.VehicleLicensePlate>{plateWithMask}</Style.VehicleLicensePlate>
        </Style.Header>
        <Style.Body>
          <Style.Fuel>
            {fuels.map((fuel: any, index: number) => (
              <CardButton
                key={index}
                id={fuel.combustivelId}
                color={fuel.corCombustivel}
              >
                {fuel.titulo}
              </CardButton>
            ))}
          </Style.Fuel>
          <Style.Value>
            <form className="box" onSubmit={postInformation}>
              <h1 className="enter-the-value">
                Digite o valor do abastecimento
              </h1>
              <Grid
                container
                flexDirection="row"
                alignItems="center"
                justifyContent="center"
              >
                <Grid item xs={8} md={8}>
                  <CustomTextField
                    mask="Currency"
                    iconText="R$"
                    onChange={changeValueRefueling}
                    value={valueRefueling}
                  />
                </Grid>
              </Grid>
              {/* <Style.Input
                onChange={changeValueRefueling}
                value={valueRefueling}
                mask="9999,99"
                placeholder="00,00"
              /> */}
              <button type="submit" disabled={loading}>
                {loading ? <CircularProgress /> : "OK"}
              </button>
            </form>
          </Style.Value>
        </Style.Body>
      </Style.Container>
      <Notification />
    </>
  );
};
