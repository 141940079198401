import React, { useState, createContext, useContext, useEffect } from "react";
import { useSessionStorage } from "../hooks/useSessionStorage";

import { IChildrenType } from "../types";
import { RefuellingData } from "../types/utils/RefuellingData";

interface StoreContextInterface {
  userAuthenticated: any;
  cardNumber: string;
  selectedFuel: string;
  modalIsOpen: boolean;
  valueRefueling: any;
  typeModal: string;
  titleModal: string;
  refuellingData: RefuellingData | null;
  gasStation: string;
  associadoId: string;
  plate: string;
  fuels: [];
  loading: boolean;
  changeCardNumber: (value: string) => void;
  changeSelectedFuel: (value: string) => void;
  changeModalIsOpen: (value: boolean) => void;
  changeValueRefueling: (value: any) => void;
  validateRefueling: (value: any) => void;
  changeUserAuthenticated: (value: any) => void;
  changeNotification: (
    type: TypeNotification,
    title: string,
    refuellingData?: RefuellingData
  ) => void;
  resetRefuelingData: () => void;
  changeGasStation: (value: string) => void;
  changePlate: (value: string) => void;
  changeFuels: (value: []) => void;
  changeassociadoId: (value: string) => void;
  changeInitialState: () => void;
  changeLoading: (value: boolean) => void;
}

type TypeNotification = "success" | "error";

export const StoreContext = createContext({} as StoreContextInterface);

export const StoreProvider = ({ children }: IChildrenType) => {
  const [cardNumber, setCardNumber] = useState("");
  const [selectedFuel, setSelectedFuel] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [valueRefueling, setValueRefueling] = useState("");
  const [loading, setLoading] = React.useState(false);

  const [typeModal, setTypeModal] = useState("");
  const [titleModal, setTitleModal] = useState("");
  const [refuellingData, setRefuellingData] = useState<RefuellingData | null>(
    null
  );
  const [gasStation, setGasStation] = useState("");

  const [associadoId, setassociadoId] = useSessionStorage("associadoId", "");
  const [plate, setPlate] = useSessionStorage("placa", "");
  const [fuels, setFuels] = useSessionStorage("combustiveis", []);
  const [userAuthenticated, setUserAuthenticated] = useSessionStorage(
    "usuarioAutenticado",
    false
  );

  const changeCardNumber = (value: string) => {
    setCardNumber(value);
  };

  const changeUserAuthenticated = (value: any) => {
    setUserAuthenticated(value);
  };

  const changeassociadoId = (value: string) => {
    setassociadoId(value);
  };

  const changePlate = (value: string) => {
    setPlate(value);
  };

  const changeFuels = (values: []) => {
    setFuels(values);
  };

  const changeValueRefueling = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValueRefueling(event.target.value);
  };

  const changeSelectedFuel = (value: string) => {
    setSelectedFuel(value);
  };

  const changeModalIsOpen = (value: boolean) => {
    setModalIsOpen(value);
  };

  const validateRefueling = () => {
    if (!valueRefueling || !selectedFuel) {
      setModalIsOpen(true);
      setTypeModal("error");
      setTitleModal(
        "A seleção do combustível e o preenchimento do valor, são necessários para concluir a venda."
      );
      return;
    }
    setModalIsOpen(true);
    setTypeModal("success");
    setTitleModal("abastecimento realizado com sucesso!");
  };

  const resetRefuelingData = () => {
    setRefuellingData(null);
  };

  const changeNotification = (
    type: TypeNotification,
    title: string,
    refuellingData?: RefuellingData
  ) => {
    setModalIsOpen(true);
    setTypeModal(type);
    setTitleModal(title);

    if (refuellingData) {
      setRefuellingData(refuellingData);
    }
  };

  const changeGasStation = (value: string) => {
    setGasStation(value);
  };

  useEffect(() => {
    setGasStation(localStorage.getItem("nomePosto") || "");
  }, []);

  const changeInitialState = () => {
    setassociadoId("");
    fuels.length = 0;
    setSelectedFuel("");
    setPlate("");
    setValueRefueling("");
    changeUserAuthenticated(false);
  };

  const changeLoading = (value: boolean) => {
    setLoading(value);
  };

  return (
    <StoreContext.Provider
      value={{
        userAuthenticated,
        cardNumber,
        selectedFuel,
        modalIsOpen,
        valueRefueling,
        typeModal,
        titleModal,
        refuellingData,
        changeCardNumber,
        changeSelectedFuel,
        changeModalIsOpen,
        changeValueRefueling,
        validateRefueling,
        changeUserAuthenticated,
        changeNotification,
        resetRefuelingData,
        gasStation,
        changeGasStation,
        plate,
        changePlate,
        fuels,
        changeFuels,
        associadoId,
        changeassociadoId,
        changeInitialState,
        loading,
        changeLoading,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};

export const useStore = () => {
  return useContext(StoreContext);
};
