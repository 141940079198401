import styled from "styled-components";

export const Container = styled.div`
  width: 44%;
  margin: 0 1%;
  height: 4.5rem;
  text-transform: uppercase;
  padding: 10px;
  text-align: start;
  background: ${(props) => props.color};
  border-radius: 11px;
  color: #fff;
  font-size: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 800;
  cursor: pointer;
  margin-bottom: 10px;
`;
