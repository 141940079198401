import React, { useState, useEffect, useCallback } from "react";
import {
  Paper,
  IconButton,
  InputBase,
  Box,
  Grid,
  Typography,
} from "@mui/material";
import { formatTextByMask } from "../../helpers/maskHelper";

const CustomTextField = React.memo(function CustomizeTextField(props: any) {
  const [value, setValue] = useState("");

  const formatField = useCallback(
    (value: string | number) => {
      const text = value.toString();
      let textFormat = formatTextByMask(text, props.mask);
      if (props.mask) {
        if (typeof value === "number") {
          if (value < 0) {
            textFormat = "-" + textFormat;
          }
        }
        return textFormat;
      }
      return text;
    },
    [props.mask, props.maskCustom]
  );

  useEffect(() => {
    if (props.value) {
      const maskedValue = formatField(props.value);
      setValue(maskedValue);
    } else {
      setValue(props.value || "");
    }
  }, [props.value]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (props.onChange) {
      event.target.value = formatField(event.target.value);
      props.onChange(event);
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (props.onKeyPress) {
      props.onKeyPress(event);
    }
  };

  const handleOnBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    if (props.onBlur) {
      props.onBlur(event);
    }
  };

  const handleOnKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (props.onKeyUp) {
      props.onKeyUp(event);
    }
  };

  return (
    <Grid container direction="column" justifyContent="flex-start" spacing={1}>
      {!!props.label && (
        <Grid item>
          <Typography
            sx={{
              color: "#022831",
              letterSpacing: 0.5,
              fontFamily: "HelveticaNeue-Regular",
              fontSize: 16,
              whiteSpace: "nowrap",
            }}
          >
            <>{props.label}</>
            {props.required && <span style={{ color: "#F6406C" }}>*</span>}
          </Typography>
        </Grid>
      )}
      <Grid item>
        <Paper
          elevation={0}
          sx={{
            border: !props.validateText
              ? "1px solid #758887"
              : "1px solid #F6406C",
            backgroundColor: !props.validateText ? "#fff" : "#FEEFEF",
            fontSize: "38px",
            borderRadius: "14px",
            p: "2px 4px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            width: "100%",
            height: "90px",
            cursor: "text",
          }}
        >
          {!!props.icon && (
            <IconButton sx={{ paddingLeft: "7px" }}>{props.icon}</IconButton>
          )}

          {!!props.iconText && (
            <Typography
              sx={{
                paddingLeft: "16px",
                paddingRight: "7px",
                fontSize: "38px",
                color: "#757575",
              }}
            >
              {props.iconText}
            </Typography>
          )}

          {!props.icon && !props.iconText && (
            <Box sx={{ paddingLeft: "16px" }} />
          )}
          <InputBase
            sx={{
              backgroundColor: "transparent !important",
              color: "rgba(2, 40, 49, 0.8)",
              "& .MuiInputBase-input": {
                cursor: "text",
                backgroundColor: "transparent !important",
                width: "100%",
              },
            }}
            disabled={props.disabled}
            placeholder={props.placeholder}
            onChange={handleChange}
            onKeyPress={handleKeyPress}
            onBlur={handleOnBlur}
            onKeyUp={handleOnKeyUp}
            value={value}
          />
        </Paper>
      </Grid>
      {!!props.validateText && (
        <Grid item>
          <Typography
            sx={{
              color: "#F6406C",
              letterSpacing: 0.5,
              fontFamily: "HelveticaNeue-Regular",
              fontSize: "12px",
            }}
          >
            {props.validateText}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
});

export { CustomTextField };
