import styled from "styled-components";

export const Container = styled.div``;

export const Body = styled.div`
  flex-direction: column;
  display: flex;
  align-items: center;
  height: calc(100vh - 48px);
  background: #52595c;

  input[type="password"]::-ms-reveal,
  input[type="password"]::-ms-clear {
    display: none;
  }

  h1 {
    text-transform: uppercase;
    margin: 1rem;
    color: #fff;
  }

  .validation-system {
    text-align: center;
    font-weight: 800;
    font-size: 44px;
  }

  .card-approach {
    font-weight: 700;
    font-size: 32px;
  }

  @media (min-height: 800px) {
    h1 {
      margin: 4rem;
    }
  }
`;

export const Logo = styled.img`
  width: 33%;
`;

export const Modal = {
  position: "absolute" as const,
  top: "30%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};
