import { AxiosError } from "axios";
import { FormEvent } from "react";

import { useStore } from "../../context/Store";

import { API } from "../../services/api";
import { RefuellingData } from "../../types/utils/RefuellingData";

export default () => {
  const {
    plate,
    associadoId,
    selectedFuel,
    valueRefueling,
    changeNotification,
    changeInitialState,
    changeLoading,
  } = useStore();

  const postInformation = async (event: FormEvent) => {
    event.preventDefault();

    const gasId = localStorage.getItem("idPosto");

    if (!valueRefueling || !selectedFuel) {
      changeNotification(
        "error",
        "A seleção do combustível e o preenchimento do valor, são necessários para concluir a venda."
      );
      return;
    }

    const args = {
      totalValorAbastecido: valueRefueling
        .replaceAll(".", "")
        .replaceAll(",", "."),
      postoId: gasId,
      combustivellId: selectedFuel,
      associadoId: associadoId,
      veiculo: {
        nome: "string",
        placa: plate,
      },
    };

    console.log(args);

    try {
      changeLoading(true);
      const res = await API.post<RefuellingData>(
        "/api/v1/Associado/abastecimento",
        args
      );

      changeNotification("success", "Abastecimento realizado!", res.data);
      changeLoading(false);
      changeInitialState();
    } catch (error: AxiosError | any) {
      const errors = error.response.data.errors;
      changeLoading(false);

      if (errors) {
        changeLoading(false);
        changeNotification("error", errors.join("\n"));
      } else {
        changeLoading(false);
        changeNotification("error", "Erro no aplicativo, tente novamente!.");
      }
    }
  };

  return {
    postInformation,
  };
};
