import React from "react";
import { Routes, Route } from "react-router-dom";
import { useStore } from "./context/Store";

import { Home, Refuel } from "./pages";

function App() {
  const { userAuthenticated } = useStore();

  return (
    <>
      <Routes>
        {userAuthenticated ? (
          <Route path="/" element={<Refuel />} />
        ) : (
          <Route path="/" element={<Home />} />
        )}
      </Routes>
    </>
  );
}

export default App;
