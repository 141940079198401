import styled from "styled-components";

export const Container = styled.header`
  width: 100%;
  height: 48px;
  background: #505050;
`;

export const NavBar = styled.nav`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const Title = styled.h1`
  font-size: 26px;
  font-weight: 600;
  color: #fff;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", b Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
`;
