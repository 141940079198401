import { RefuellingData } from "../../../types/utils/RefuellingData";
import {
  maskBrazilianCurrency,
  maskCNPJ,
  maskDecimal,
  maskPlate,
} from "../../../helpers/maskHelper";

import logoTransparent from "../../../assets/clubgas_transparent.png";

import * as Styles from "./styles";
import { forwardRef } from "react";

interface RefuellingReceiptProps {
  refuellingData: RefuellingData;
}

const RefuellingReceipt = forwardRef<HTMLDivElement, RefuellingReceiptProps>(
  ({ refuellingData }: RefuellingReceiptProps, ref) => {
    return (
      <Styles.Container ref={ref}>
        <Styles.ReceiptContainer>
          <Styles.Logo src={logoTransparent} alt="ClubGas Logo" />
          <Styles.Text>{refuellingData.nomePostoApp}</Styles.Text>
          <Styles.Text>{maskCNPJ(refuellingData.cnpj)}</Styles.Text>
          <Styles.Text>
            {refuellingData.cidade} {refuellingData.uf}
          </Styles.Text>

          <Styles.ItemRow>
            <Styles.TextSecondary>{refuellingData.data}</Styles.TextSecondary>
            <Styles.TextSecondary>
              {refuellingData.horasAbastecimento}
            </Styles.TextSecondary>
          </Styles.ItemRow>

          <Styles.DottedLine />
          <Styles.Text>Comprovante de Abastecimento</Styles.Text>
          <Styles.Text>NÃO É DOCUMENTO FISCAL </Styles.Text>
          <Styles.DottedLine />

          <Styles.ItemRow>
            <Styles.Text>Placa:</Styles.Text>
            <Styles.Text>{maskPlate(refuellingData.placa)}</Styles.Text>
          </Styles.ItemRow>

          <Styles.ItemRow>
            <Styles.Text>Combustível:</Styles.Text>
            <Styles.Text>{refuellingData.nomeCombustivel}</Styles.Text>
          </Styles.ItemRow>

          <Styles.ItemRow>
            <Styles.Text>Valor:</Styles.Text>
            <Styles.Text>
              {maskBrazilianCurrency(refuellingData.valorAbastecido)}
            </Styles.Text>
          </Styles.ItemRow>

          <Styles.ItemRow>
            <Styles.Text>Valor com desconto:</Styles.Text>
            <Styles.Text>
              {maskBrazilianCurrency(refuellingData.valor)}
            </Styles.Text>
          </Styles.ItemRow>

          <Styles.ItemRow>
            <Styles.Text>Desconto:</Styles.Text>
            <Styles.Text>
              {maskBrazilianCurrency(refuellingData.desconto)}
            </Styles.Text>
          </Styles.ItemRow>

          <Styles.ItemRow>
            <Styles.Text>Litros:</Styles.Text>
            <Styles.Text>
              {maskDecimal(refuellingData.litrosAbastecido)}
            </Styles.Text>
          </Styles.ItemRow>

          <Styles.ItemRow>
            <Styles.Text>Preço Litro Posto:</Styles.Text>
            <Styles.Text>
              {maskBrazilianCurrency(refuellingData.preçoBomba)}
            </Styles.Text>
          </Styles.ItemRow>

          <Styles.ItemRow>
            <Styles.Text>Preço Litro ClubGas:</Styles.Text>
            <Styles.Text>
              {maskBrazilianCurrency(refuellingData.preçoClubGas)}
            </Styles.Text>
          </Styles.ItemRow>

          <Styles.ItemRow>
            <Styles.Text>Desconto por Litro:</Styles.Text>
            <Styles.Text>
              {maskBrazilianCurrency(refuellingData.descontoPorLitros)}
            </Styles.Text>
          </Styles.ItemRow>

          <Styles.DottedLine />
          <Styles.TextSecondary>Autenticação</Styles.TextSecondary>
          <Styles.TextSecondary>
            {refuellingData.idAbastecimento}
          </Styles.TextSecondary>
        </Styles.ReceiptContainer>
      </Styles.Container>
    );
  }
);

RefuellingReceipt.displayName = "RefuellingReceipt";

export { RefuellingReceipt };
