import React from "react";

import { CardButtonProps } from "../../types";
import { useStore } from "../../context/Store";

import * as Style from "./styles";

export const CardButton = ({ id, children, color }: CardButtonProps) => {
  const { changeSelectedFuel, selectedFuel } = useStore();

  const setSelectedFuel = (id: string) => {
    changeSelectedFuel(id);
  };

  return (
    <Style.Container
      color={
        selectedFuel === ""
          ? color + "ab"
          : selectedFuel === id
          ? color
          : "#ccc"
      }
      onClick={() => setSelectedFuel(id)}
    >
      {children}
    </Style.Container>
  );
};
