import styled from "styled-components";

export const TitleModal = styled.h1`
  max-width: 50vw;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
`;

export const ButtonsModalContainer = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
`;

export const ButtonModal = styled.button`
  width: 100%;
  height: 90px;
  border-radius: 14px;
  border: none;
  cursor: pointer;
  font-size: 34px;
  margin-top: 4rem;
  :focus {
    outline: none;
  }
`;
