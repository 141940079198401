import React, { useEffect } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import { Grid, Modal, Typography, Button, Box, Alert } from "@mui/material";

import { Notification } from "../../components/Notification";
import { Header } from "../../components";
import { LOGO } from "../../config";

import { useStore } from "../../context/Store";
import useHome from "./useHome";

import * as Style from "./styles";

export const Home = () => {
  const {
    gasToken,
    changeGasToken,
    handleGasTokenSubmit,
    modalGasTokenIsOpen,
    cardNumber,
    changeCardNumber,
    deleteCardNumber,
    getInformation,
    alert,
    cardNumberRef,
  } = useHome();

  const { gasStation } = useStore();

  useEffect(() => {
    getInformation();
  }, []);

  useEffect(() => {
    function handleKeyPress(event: KeyboardEvent) {
      if (event.key === "Enter" && gasToken) {
        handleGasTokenSubmit();
      }
    }

    document.addEventListener("keypress", handleKeyPress);

    return () => {
      document.removeEventListener("keypress", handleKeyPress);
    };
  }, [gasToken]);

  return (
    <>
      <Style.Container>
        <Header>{gasStation}</Header>
        <Style.Body>
          <h1>Sistema de Validação - Cartão ClubGas</h1>
          <Style.Logo src={LOGO} />
          <h1>APROXIME O CARTÃO NO LEITOR CLUBGAS</h1>
          <InputLabel
            sx={{
              width: "43%",
              fontSize: "30px",
              color: "#fff",
              fontWeight: "600",
            }}
          >
            LEITURA DO CARTÃO::
          </InputLabel>
          <TextField
            inputRef={cardNumberRef}
            value={cardNumber}
            onChange={changeCardNumber}
            onKeyDown={deleteCardNumber}
            type="password"
            focused
            inputProps={{
              autocomplete: "no",
            }}
            sx={{
              width: "43%",
              background: "#fff",
              borderRadius: "7px",
              "& .MuiOutlinedInput-input": {
                height: "94px",
                fontSize: "72px",
                textAlign: "center",
                background: "#313637",
                color: "#fff",
              },
              label: { fontSize: "2rem" },
            }}
          />
        </Style.Body>
      </Style.Container>
      <Modal
        open={modalGasTokenIsOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={Style.Modal}>
          <Grid
            container
            direction="column"
            justifyContent="space-between"
            alignItems="stretch"
            spacing={3}
          >
            {!!alert && (
              <Grid item>
                <Alert severity="error">
                  <strong>{alert}</strong>
                </Alert>
              </Grid>
            )}
            <Grid item>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Confirme o TOKEN do Posto
              </Typography>
            </Grid>
            <Grid item>
              <TextField
                value={gasToken}
                onChange={changeGasToken}
                type="text"
                sx={{
                  width: "100%",
                  background: "#fff",
                  borderRadius: "7px",
                  "& .MuiOutlinedInput-input": {
                    height: "24px",
                    fontSize: "12px",
                    textAlign: "center",
                    background: "#313637",
                    color: "#fff",
                  },
                  label: { fontSize: "2rem" },
                }}
              />
            </Grid>
            <Grid item>
              <Button
                type="button"
                size="large"
                variant="contained"
                onClick={handleGasTokenSubmit}
              >
                SALVAR
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Notification shouldRefreshPageOnClose />
    </>
  );
};
