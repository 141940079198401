import styled from "styled-components";

export const Container = styled.div`
  padding: 0.875rem;
  max-height: 400px;
  background: white;
  overflow-y: auto;

  @media print {
    max-height: 100%;
  }
`;

export const ReceiptContainer = styled.div`
  padding: 1.5rem;
  border: 1px dashed #008d0e;
  background: #fff2cd;

  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: center;
`;

export const Logo = styled.img`
  width: 200px;
`;

export const Text = styled.p`
  margin: 0;
  color: #0a0700;
  font-size: 0.75rem;
  font-weight: 700;
  text-transform: uppercase;
`;

export const TextSecondary = styled.p`
  margin: 0;
  color: #5f6363;
  font-size: 0.625rem;
  font-weight: 700;
  text-transform: uppercase;
`;

export const ItemRow = styled.div`
  width: 100%;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
`;

export const DottedLine = styled.hr`
  margin: 0;
  width: 100%;
  border: none;
  border: 1px dashed #5f6363;
`;
